import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import noop from "lodash/noop";

import styles from "../../styles/common/hexagon.module.css";

import {CloseOutlined} from "@ant-design/icons";

const Hexagon = ({
  className,
  minimizedClassName,
  bigLogo,
  title,
  description,
  logo,
  nominationHexagon,
  notNominated,
  allowToNominate,
  onMouseEnter,
  onMouseLeave,
  showDescription,
  onOpenModal
}) => {
  const selectedContainerRef = useRef();

  const onClickOutside = event => {
    // if (
    //   selectedContainerRef &&
    //   selectedContainerRef.current &&
    //   selectedContainerRef.current.classList.contains(styles.visible) &&
    //   !selectedContainerRef.current.contains(event.target)
    // ) {
    //   onMouseLeave();
    // }
  };

  const toAwardLabel = title => {
    return title?.split(" - ")[0]?.trim();
  };

  const isDescriptionInViewport = () => {
    const el = selectedContainerRef && selectedContainerRef.current;
    if (!el || !showDescription || (window.innerWidth || document.documentElement.clientWidth) < 768) return null;

    const rect = el.getBoundingClientRect(),
      descriptionWidth = 280,
      sideWidth = 55;

    return rect.x - descriptionWidth < 0
      ? -1
      : rect.x + descriptionWidth + sideWidth > (window.innerWidth || document.documentElement.clientWidth)
      ? 1
      : 0;
  };

  const getSelectedContainerStyle = () => {
    const el = selectedContainerRef && selectedContainerRef.current;
    const value = isDescriptionInViewport();
    if (!value || !el) return {};

    const offset = nominationHexagon ? 5 : 20;
    return value > 0 ? {right: `${offset}%`} : {left: `${offset}%`};
  };

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        ref={selectedContainerRef}
        className={cx(styles.selectedContainer, className, {
          [styles.visible]: showDescription
        })}
        style={getSelectedContainerStyle()}
        onMouseLeave={onMouseLeave}
      >
        <div className={styles["close-button"]} onClick={onMouseLeave}>
          <CloseOutlined />
        </div>
        <div className={styles.selectedNominationContent}>
          {description}
          {allowToNominate && notNominated ? (
            <div className={styles["no-nomination"]}>
              <span>Nominations will not be accepted for this category</span>
            </div>
          ) : (
            <div className={styles.nominationVoteLink}>
              {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a onClick={() => onOpenModal(title)}>NOMINATE NOW</a>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx(
          nominationHexagon ? styles.container : styles["winner-container"],
          className,
          minimizedClassName,
          {
            [styles.invisible]: showDescription
          },
          {[styles.akm]: !nominationHexagon && bigLogo}
        )}
        onMouseEnter={onMouseEnter}
        onClick={onMouseEnter}
      >
        <div className={nominationHexagon ? styles.content : cx(styles["award-content"])}>
          <div className={styles.title} dangerouslySetInnerHTML={{__html: toAwardLabel(title)}} />
          {!nominationHexagon && (
            <div className={cx(styles["award-winner-logo"])}>
              <img alt="Logo" src={logo} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Hexagon.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.node,
  showDescription: PropTypes.bool,
  onClick: PropTypes.func,
  onOpenModal: PropTypes.func
};

Hexagon.defaultProps = {
  onClick: noop,
  onOpenModal: noop
};

export default Hexagon;
