import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import styles from "../../styles/home/logo.module.css";
import logo from "../../assets/images/logo.svg";

const Logo = () => (
  <div className={styles.container}>
    <img alt="Main logo" src={logo} />
  </div>
);

Logo.propTypes = {
  strings: PropTypes.shape({
    home: PropTypes.shape({
      logo: PropTypes.shape({
        header: PropTypes.string.isRequired
      })
    })
  }).isRequired
};

const mapStateToProps = ({settings}) => settings;
export default connect(mapStateToProps)(Logo);
