import {combineReducers} from "redux";

import nominationForms from "./nomination-forms.reducer";
import files from "./files.reducer";
import settings from "./settings.reducer";

export default combineReducers({
  nominationForms,
  files,
  settings
});
