import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import get from "lodash/get";
import last from "lodash/last";
import cx from "classnames";

import * as nominationFormsActions from "../../actions/nomination-forms.actions";

import Hexagon from "../common/hexagon";
import NominationModal from "./nomination-modal";
import SuccessModal from "./success-modal";
import {arrayToSubArrays} from "../../helpers/util";

import styles from "../../styles/home/categories.module.css";

class Categories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedId: -1,
      showNominationModal: false,
      showSuccessModal: false,
      lastSubmittedName: "",
      lastSubmittedNomination: "",
      nomination: null,
      windowWidth: window.innerWidth
    };

    this.setSelectedId = this.setSelectedId.bind(this);
    this.handleToggleNominationModal = this.handleToggleNominationModal.bind(this);
    this.handleToggleSuccessModal = this.handleToggleSuccessModal.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.toTitleCase = this.toTitleCase.bind(this);
    this.notNominated = this.notNominated.bind(this);
    this.isSubmissionActive = this.isSubmissionActive.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.setState({windowWidth: window.innerWidth});
    });
  }

  componentDidUpdate(prevProps) {
    const {
      nominationForms: {loading, data}
    } = this.props;

    if (prevProps.nominationForms.loading && !loading && data.length > prevProps.nominationForms.data.length) {
      this.setState({lastSubmittedName: get(last(data), "firstName", "")});
      this.setState({lastSubmittedNomination: get(last(data), "nomination", "")});
      this.handleToggleSuccessModal();
    }
  }

  setSelectedId = selectedId => {
    this.setState({selectedId});
  };

  handleToggleNominationModal = nomination => {
    this.setState({
      showNominationModal: !this.state.showNominationModal,
      nomination: this.state.showNominationModal ? null : nomination
    });
  };

  handleToggleSuccessModal = () => {
    this.setState({showSuccessModal: !this.state.showSuccessModal});
  };

  closeSuccessModal = () => {
    this.setState({lastSubmittedName: "", lastSubmittedNomination: ""});
    this.handleToggleSuccessModal();
  };

  onSubmitForm = values => {
    this.handleToggleNominationModal();
    const {createNominationForm} = this.props;
    createNominationForm(values);
  };

  toTitleCase = phrase => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map(word => (word === "dei" ? "DEI" : word.charAt(0).toUpperCase() + word.slice(1)))
      .join(" ");
  };

  notNominated = nominationId => {
    return [].includes(nominationId);
  };

  isSubmissionActive = () => {
    const {
      settings: {data}
    } = this.props;
    try {
      const eventFinishDate = new Date(data.home.countdown.eventStartDate);

      return new Date() < eventFinishDate;
    } catch {
      return false;
    }
  };

  render() {
    const {
      settings: {strings, data},
      nominationForms
    } = this.props;
    const {
      selectedId,
      showNominationModal,
      showSuccessModal,
      lastSubmittedName,
      lastSubmittedNomination,
      nomination,
      windowWidth
    } = this.state;

    return (
      <div>
        <NominationModal
          nomination={nomination}
          loading={nominationForms.loading}
          visible={showNominationModal}
          onClose={this.handleToggleNominationModal}
          onSubmit={this.onSubmitForm}
        />
        <SuccessModal
          visible={showSuccessModal}
          name={lastSubmittedName}
          nomination={this.toTitleCase(lastSubmittedNomination)}
          onClose={this.handleToggleSuccessModal}
        />

        <div className={styles.container}>
          <div className={styles.header}>{strings.home.categories.header}</div>
          <div className={styles.notice}>{strings.home.categories.notice}</div>

          <div className={styles["nominations-container"]}>
            {arrayToSubArrays(data.home.awardWinners, windowWidth > 610 ? [4] : [2]).map((awardWinners, awardWinnersIdx) => (
              <div className={styles["winners-row"]} key={awardWinnersIdx}>
                {awardWinners.map((awardWinner, idx) => (
                  <Hexagon
                    key={idx}
                    className={cx(
                      {[styles["selected-nomination-container"]]: awardWinner.id === selectedId},
                      {[styles["winner-award-container"]]: awardWinner.id !== selectedId}
                    )}
                    minimizedClassName={styles.minimized}
                    bigLogo={awardWinner.id === 11}
                    title={awardWinner.title}
                    description={awardWinner.description}
                    logo={awardWinner.logo}
                    nominationHexagon={false}
                    notNominated={this.notNominated(awardWinner.id)}
                    allowToNominate={this.isSubmissionActive()}
                    onMouseEnter={() => this.setSelectedId(awardWinner.id)}
                    onMouseLeave={() => this.setSelectedId(-1)}
                    showDescription={awardWinner.id === selectedId}
                    onOpenModal={this.handleToggleNominationModal}
                  />
                ))}
              </div>
            ))}

            {arrayToSubArrays(data.home.categories.nominations, windowWidth > 610 ? [3, 4] : [2]).map((nominations, nominationsIdx) => (
              <div key={nominationsIdx} className={styles["nominations-row"]}>
                {nominations.map((nomination, idx) => (
                  <Hexagon
                    key={idx}
                    className={cx(
                      {[styles["selected-nomination-container"]]: nomination.id === selectedId},
                      {[styles["winner-award-container"]]: nomination.id !== selectedId}
                    )}
                    minimizedClassName={styles.minimized}
                    title={nomination.title}
                    description={nomination.description}
                    nominationHexagon={true}
                    notNominated={this.notNominated(nomination.id)}
                    allowToNominate={this.isSubmissionActive()}
                    onMouseEnter={() => this.setSelectedId(nomination.id)}
                    onMouseLeave={() => this.setSelectedId(-1)}
                    showDescription={nomination.id === selectedId}
                    onOpenModal={this.handleToggleNominationModal}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Categories.propTypes = {
  settings: PropTypes.shape({
    strings: PropTypes.shape({
      home: PropTypes.shape({
        categories: PropTypes.shape({
          header: PropTypes.string.isRequired
        })
      })
    }).isRequired,
    data: PropTypes.object.isRequired
  }),
  nominationForms: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object
  })
};

const mapStateToProps = ({settings, nominationForms}) => ({settings, nominationForms});
const mapDispatchToProps = dispatch => bindActionCreators(nominationFormsActions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
