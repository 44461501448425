/* eslint-disable import/no-anonymous-default-export */
import kinessoLogo from "../assets/images/icons/kinesso.svg";
import akmLogo from "../assets/images/icons/akm.svg";
import acxiomLogo from "../assets/images/icons/acxiom.svg";
import matterkindLogo from "../assets/images/icons/matterkind.svg";

const initialState = {
  strings: {
    home: {
      logo: {
        header: "AKMMYS"
      },
      countdown: {
        header: "SUBMISSION DEADLINE",
        closedHeader: "SUBMISSIONS HAVE CLOSED",
        divider: ":",
        days: "days",
        hours: "hours",
        minutes: "minutes",
        seconds: "seconds"
      },
      categories: {
        header: "AWARDS CATEGORIES",
        notice: "Hover over the awards to see descriptions and nominate"
      }
    }
  },
  data: {
    home: {
      countdown: {
        eventStartDate: "2022-10-13T03:59:59Z"
      },
      awardWinners: [
        {
          id: 11,
          logo: akmLogo,
          title: "BEST CROSS-AKM <br/> CLIENT WIN",
          description:
            "As always, a huge focus for us is being ‘better together’, realizing our joint potential. This award goes to the lead team that best demonstrated how to work across AKM to get a significant client win."
        },
        {
          id: 12,
          logo: acxiomLogo,
          title: "BEST CLIENT WIN - Acxiom",
          description: "This award would goes to the Acxiom team that best demonstrated how to get a significant client win."
        },
        {
          id: 13,
          logo: kinessoLogo,
          title: "BEST CLIENT WIN - Kinesso",
          description: "This award goes to the Kinesso team that best demonstrated how to get a significant client win."
        },
        {
          id: 14,
          logo: matterkindLogo,
          title: "BEST CLIENT WIN - Matterkind",
          description: "This award goes to the Matterkind team that best demonstrated how to get a significant client win."
        }
      ],
      categories: {
        nominations: [
          {
            id: 1,
            title: "INNOVATION",
            description:
              "This award goes to the person or team that has created the most innovative solution, product, or internal business-related process."
          },
          {
            id: 2,
            title: "CREATIVITY",
            description:
              "This award goes to the person or team with the most creative thinking. It is not limited to design creativity but all aspects of ‘being creative’ to solve a problem or create something new."
          },
          {
            id: 3,
            title: "CLIENT EXCELLENCE",
            description:
              "This award goes to the person or team who has delivered the biggest impact to existing clients in terms of effectiveness and/ or efficiency."
          },
          {
            id: 4,
            title: "AKM EXCELLENCE",
            description:
              "This award goes to the person or team who has delivered the biggest impact to our own business(es) in terms of effectiveness and/ or efficiency."
          },
          {
            id: 5,
            title: "DEI AWARD",
            description:
              "This award goes to the person or team that has made the most significant contribution to Diversity, Equity and Inclusion. That could be to our culture and change within, or to our offerings in terms of how we help our clients promote DEI in their marketing."
          },
          {
            id: 6,
            title: "COLLABORATION CLIENT WIN",
            description:
              "Collaboration is critical to success across all of AKM and this award goes to the person or team that best demonstrates it. This award goes to the person or team that demonstrated collaboration in support of a client win."
          },
          {
            id: 7,
            title: "COLLABORATION INTERNAL",
            description:
              "Collaboration is critical to success across all of AKM and this award goes to the person or team that best demonstrates it. This award goes to the person or team that best demonstrated collaboration internally."
          },
          {
            id: 8,
            title: "ACXIOM VALUES AWARD",
            description:
              "Our values are the core of who we are. While we have values that differ in words, the values we share still represent far more similarities than differences. That said, the Acxiom Values Award go to the person or team in Acxiom that best exemplifies their respective values: Associates, Collaboration, eXcellence, Innovation, Outcomes, eMpowerment."
          },
          {
            id: 9,
            title: "KINESSO / MATTERKIND VALUES AWARD",
            description:
              "Our values are the core of who we are. While we have values that differ in words, the values we share still represent far more similarities than differences. That said, the Values Awards go to the person or team in Kinesso/Matterkind that best exemplifies their values: Truth, Togetherness, Trailblazing."
          },
          {
            id: 10,
            title: "ROOKIE AWARD",
            description:
              "This award goes to the individual, who at the time submissions close, has been with A, K, M for less than 12 months and who, we judge to have made the biggest single difference and or, represents the very best of all we are and want to be. This award is designed to celebrate young talent, early in their careers, rather than experienced talent."
          }
        ]
      }
    }
  }
};

export default () => initialState;
