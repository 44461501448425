import React, {Component} from "react";

import Logo from "./logo";
import Countdown from "./countdown";
import Categories from "./categories";

import styles from "../../styles/home/index.module.css";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.page1}>
          <Logo />
          <Countdown />
        </div>
        <Categories />
      </div>
    );
  }
}
