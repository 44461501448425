import React from "react";
import PropTypes from "prop-types";

import {Button, Modal} from "antd";
import cx from "classnames";
import noop from "lodash/noop";

import formLogo from "../../assets/images/logo.svg";
import styles from "../../styles/home/nomination-modal.module.css";

const SuccessModal = ({visible, name, nomination, onClose}) => {
  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      width={600}
      wrapClassName={styles.container}
      onCancel={onClose}
      footer={
        <div className={styles["modal-footer"]}>
          <Button key="back" className={cx(styles["input-button"], styles["modal-footer-button"])} onClick={onClose}>
            CLOSE
          </Button>
        </div>
      }
    >
      <div className={styles.content}>
        <div className={styles["form-logo"]}>
          <img alt="Form logo" src={formLogo} />
        </div>

        <div className={styles["success-thx"]}>THANK YOU{name ? ", " + name.toUpperCase() : ""}!</div>
        <div className={styles["success-text"]}>We've received your submission for the {nomination} category.</div>
      </div>
    </Modal>
  );
};

SuccessModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

SuccessModal.defaultProps = {
  visible: false,
  onClose: noop
};

export default SuccessModal;
