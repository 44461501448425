import React, {Component} from "react";
import {Container} from "reactstrap";

import styles from "../styles/layout.module.css";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className={styles.container}>
        <Container>{this.props.children}</Container>
      </div>
    );
  }
}
