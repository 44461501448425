import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {Form, Modal, Button, Input, Space, Upload, message} from "antd";
import {PlusOutlined, MinusCircleOutlined, UploadOutlined} from "@ant-design/icons";
import cx from "classnames";
import get from "lodash/get";
import noop from "lodash/noop";

import formLogo from "../../assets/images/logo.svg";
import styles from "../../styles/home/nomination-modal.module.css";
import "../../styles/home/nomination-modal.css";
import ENDPOINTS from "../../constants/api";
import * as filesActions from "../../actions/files.actions";

const {TextArea} = Input;

const FormLabel = ({children, required}) => (
  <div>
    {children} {required && <span className={styles["required-label"]}>(required)</span>}
  </div>
);
FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  required: PropTypes.bool
};
FormLabel.defaultProps = {
  required: false
};

const NominationModal = ({nomination, visible, loading, onClose, onSubmit, files, startUpload, addFile, failUpload, deleteFile}) => {
  const form = useRef();
  const [errors, setErrors] = useState([]);

  const handleClose = () => {
    setErrors([]);
    onClose();
  };

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      width={600}
      wrapClassName={styles.container}
      onCancel={handleClose}
      footer={
        <div className={styles["modal-footer"]}>
          <Button key="back" className={cx(styles["input-button"], styles["modal-footer-button"])} onClick={handleClose} disabled={loading}>
            GO BACK
          </Button>
          <Button
            key="submit"
            className={cx(styles["submit-button"], styles["modal-footer-button"])}
            onClick={() => form.current.submit()}
            loading={loading}
          >
            SUBMIT
          </Button>
        </div>
      }
    >
      <div className={styles.content}>
        <Form
          ref={form}
          name="nominationSubmissionForm"
          onFinish={values => {
            onSubmit({...values, nomination, supportingFileIds: files.data?.map(file => file.id)});
            setErrors([]);
          }}
          onFinishFailed={({errorFields}) => setErrors(errorFields)}
          initialValues={{
            nomination,
            entryCredits: [
              {
                firstName: "",
                lastName: "",
                title: "",
                company: ""
              }
            ]
          }}
          requiredMark={false}
        >
          <div className={styles["form-logo"]}>
            <img alt="Form logo" src={formLogo} />
          </div>

          <div className={styles.title}>Please provide your details:</div>

          <div className={styles.row}>
            <Form.Item
              name="firstName"
              label={<FormLabel required>Nominee First Name</FormLabel>}
              className={cx(styles.input)}
              required={true}
              rules={[{required: true, message: "Please input Nominee First Name."}]}
            >
              <Input className={cx(styles["input-border"])} placeholder="Nominee First Name" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={<FormLabel required>Nominee Last Name</FormLabel>}
              className={cx(styles.input, styles.right)}
              required={true}
              rules={[{required: true, message: "Please input your Nominee Last Name."}]}
            >
              <Input className={cx(styles["input-border"])} placeholder="Nominee Last Name" />
            </Form.Item>
          </div>

          <div className={styles.row}>
            <Form.Item
              name="email"
              label={<FormLabel required>Nominee Email</FormLabel>}
              className={cx(styles.input)}
              required={true}
              rules={[
                {required: true, message: "Please input your Nominee Email."},
                {type: "email", message: "The input is not valid E-mail!"}
              ]}
            >
              <Input className={cx(styles["input-border"])} placeholder="Nominee Email" />
            </Form.Item>
            <Form.Item
              name="jobTitle"
              label={<FormLabel required>Nominee Job Title</FormLabel>}
              className={cx(styles.input, styles.right)}
              required={true}
              rules={[{required: true, message: "Please input your Nominee Job Title."}]}
            >
              <Input className={cx(styles["input-border"])} placeholder="Nominee Job Title" />
            </Form.Item>
          </div>

          <div className={styles.row}>
            <Form.Item
              name="company"
              label={<FormLabel required>Nominee Company</FormLabel>}
              className={cx(styles.input, styles.half)}
              required={true}
              rules={[{required: true, message: "Please input your Nominee Company."}]}
            >
              <Input className={cx(styles["input-border"])} placeholder="Nominee Company" />
            </Form.Item>
          </div>

          <div className={styles["row-title"]}>
            <span className={styles["row-label"]}>Tell us why your entry should win:</span>
          </div>

          <div className={cx(styles.row, styles.textarea)}>
            <Form.Item
              name="summary"
              label={<FormLabel required>Summary</FormLabel>}
              className={cx(styles.input)}
              required={true}
              rules={[{required: true, message: "Please input Summary."}]}
            >
              <TextArea
                className={cx(styles["input-border"])}
                showCount
                maxLength={300}
                bordered={false}
                placeholder="Summary"
                autoSize={{minRows: 3}}
              />
            </Form.Item>
          </div>

          <div className={cx(styles.row, styles.textarea)}>
            <Form.Item
              name="report"
              label={<FormLabel required>Report (objective, strategy and results)</FormLabel>}
              className={cx(styles.input)}
              required={true}
              rules={[{required: true, message: "Please input Report."}]}
            >
              <TextArea
                className={cx(styles["input-border"])}
                showCount
                maxLength={3000}
                bordered={false}
                placeholder="Report"
                autoSize={{minRows: 3}}
              />
            </Form.Item>
          </div>

          <div className={styles["row-title"]}>
            <span className={styles["row-label"]}>Nominator Credits (not required):</span> (team members on the campaign)
          </div>
          <div>
            <Form.List
              name="entryCredits"
              rules={[
                {
                  validator: async (_, entryCredits) => {
                    if (!entryCredits || entryCredits.length < 1) {
                      return Promise.reject(new Error("At least 1 entry"));
                    }
                  }
                }
              ]}
            >
              {(fields, {add, remove}, {errors}) => (
                <>
                  {fields.map(({key, name, fieldKey, ...restField}) => (
                    <Space key={key} style={{display: "flex", marginBottom: 8}} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, "firstName"]}
                        fieldKey={[fieldKey, "firstName"]}
                        label={<FormLabel required>First Name</FormLabel>}
                        className={cx(styles.input)}
                        required={true}
                        rules={[{required: true, message: "Please Input Entry Credit First Name."}]}
                      >
                        <Input className={cx(styles["input-border"])} placeholder="First Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "lastName"]}
                        fieldKey={[fieldKey, "lastName"]}
                        label={<FormLabel required>Last Name</FormLabel>}
                        className={cx(styles.input)}
                        required={true}
                        rules={[{required: true, message: "Please Input Entry Credit Last Name."}]}
                      >
                        <Input className={cx(styles["input-border"])} placeholder="Last Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "title"]}
                        fieldKey={[fieldKey, "title"]}
                        label={<FormLabel required>Title</FormLabel>}
                        className={cx(styles.input)}
                        required={true}
                        rules={[{required: true, message: "Please Input Entry Credit Title."}]}
                      >
                        <Input className={cx(styles["input-border"])} placeholder="Title" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "company"]}
                        fieldKey={[fieldKey, "company"]}
                        label={<FormLabel required>Company</FormLabel>}
                        className={cx(styles.input)}
                        required={true}
                        rules={[{required: true, message: "Please Input Entry Credit Company."}]}
                      >
                        <Input className={cx(styles["input-border"])} placeholder="Company" />
                      </Form.Item>
                      {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(name)} /> : null}
                    </Space>
                  ))}
                  <Form.Item>
                    <div className={styles["add-button"]}>
                      <Button className={styles["input-button"]} onClick={() => add()} block icon={<PlusOutlined />}>
                        Add credit
                      </Button>
                    </div>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className={styles["row-title"]}>
            <span className={styles["row-label"]}>Website Link (not required):</span> (If you have a website that shows off your work, you
            should add the link here)
          </div>

          <div className={styles.row}>
            <Form.Item name="website" className={cx(styles.input, styles["no-label"])}>
              <Input className={cx(styles["input-border"])} placeholder="Website Link" />
            </Form.Item>
          </div>

          <div className={styles["row-title"]}>
            <span className={styles["row-label"]}>Video Link (not required):</span> (Video has to be already created-do NOT reach out to
            creative team)
          </div>

          <div className={styles.row}>
            <Form.Item name="videoUrl" className={cx(styles.input, styles["no-label"])}>
              <Input className={cx(styles["input-border"])} placeholder="Video Link" />
            </Form.Item>
          </div>

          <div className={styles["row-title"]}>
            <span className={styles["row-label"]}>Supporting File(s) (not required):</span> (PDF/DOC/PNG/JPG)
          </div>
          <div className={cx(styles.mb20)}>
            <Upload
              name="files"
              action={ENDPOINTS.FILES.UPLOAD}
              beforeUpload={file =>
                [
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "application/pdf",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ].includes(file.type)
                  ? true
                  : Upload.LIST_IGNORE
              }
              onChange={info => {
                switch (info.file.status) {
                  case "uploading": {
                    startUpload();
                    break;
                  }
                  case "done": {
                    message.success(`${info.file.name} file uploaded successfully`);
                    addFile(info.file.response);
                    break;
                  }
                  case "removed": {
                    const fileId = get(info, "file.response.id");
                    deleteFile(fileId);
                    break;
                  }
                  case "error": {
                    message.error(`${info.file.name} file upload failed.`);
                    failUpload();
                    break;
                  }
                  default:
                    break;
                }
              }}
              progress={{
                strokeColor: {
                  "0%": "#ffe9a0",
                  "100%": "#e4b936"
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`
              }}
            >
              <Button className={styles["input-button"]} icon={<UploadOutlined />} disabled={files.loading} loading={files.loading}>
                {files.loading ? "Uploading" : "Choose Files"}
              </Button>
            </Upload>
          </div>

          <Form.ErrorList
            errors={errors
              .reduce((memo, field) => (field.errors.length ? [...memo, ...field.errors] : memo), [])
              .filter(Boolean)
              .map(error => (
                <div style={{color: "red"}}>{error}</div>
              ))}
          />
        </Form>
      </div>
    </Modal>
  );
};

NominationModal.propTypes = {
  nomination: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

NominationModal.defaultProps = {
  nomination: "",
  visible: false,
  loading: false,
  onClose: noop,
  onSubmit: noop
};

const mapStateToProps = ({files}) => ({files});
const mapDispatchToProps = dispatch => bindActionCreators(filesActions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NominationModal);
