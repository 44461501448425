/* eslint-disable import/prefer-default-export */

/**
 * Action Status Type
 * @typedef {Object} ActionStatus
 * @property {string} START
 * @property {string} SUCCESS
 * @property {string} FAILED
 */

/**
 * Creates actions with statuses
 * @param {string} namespace
 * @param {string} action
 * @returns {ActionStatus}
 */
const createStatusActions = (namespace, action) =>
  ["START", "SUCCESS", "FAILED"].reduce((memo, actionType) => ({...memo, [actionType]: [namespace, action, actionType].join(".")}), {});

const nominationForms = "nominationForms";
export const FETCH_NOMINATION_FORMS = createStatusActions(nominationForms, "FETCH_ALL");
export const CREATE_NOMINATION_FORM = createStatusActions(nominationForms, "CREATE");

const files = "files";
export const ADD_FILE = createStatusActions(files, "ADD");
export const DELETE_FILE = createStatusActions(files, "DELETE");
