import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CountdownBase from "react-countdown";

import styles from "../../styles/home/countdown.module.css";

const DIVIDER = "divider";

const CountdownDigits = ({strings, completed, ...digits}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>{completed ? strings.home.countdown.closedHeader : strings.home.countdown.header}</div>

      {(!completed && (
        <div className={styles["digits-container"]}>
          {["days", DIVIDER, "hours", DIVIDER, "minutes", DIVIDER, "seconds"].map((key, idx) =>
            key === DIVIDER ? (
              <div key={idx} className={styles.symbols}>
                {strings.home.countdown[key]}
              </div>
            ) : (
              <div key={idx} className={styles.digits}>
                {digits[key]}
                <label>{strings.home.countdown[key]}</label>
              </div>
            )
          )}
        </div>
      )) ||
        null}
    </div>
  );
};

const Countdown = ({strings, data}) => (
  <CountdownBase date={new Date(data.home.countdown.eventStartDate)} renderer={props => <CountdownDigits {...props} strings={strings} />} />
);

Countdown.propTypes = {
  strings: PropTypes.shape({
    home: PropTypes.shape({
      countdown: PropTypes.shape({
        header: PropTypes.string.isRequired
      })
    })
  }).isRequired
};

const mapStateToProps = ({settings}) => settings;
export default connect(mapStateToProps)(Countdown);
