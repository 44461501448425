/* eslint-disable import/no-anonymous-default-export */

import {ADD_FILE, DELETE_FILE} from "../actions/action-types";

const initialState = {data: [], loading: false, error: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILE.START:
    case DELETE_FILE.START:
      return {...state, loading: true};
    case ADD_FILE.SUCCESS:
      return {
        loading: false,
        data: [...state.data, action.data],
        error: null
      };
    case DELETE_FILE.SUCCESS:
      return {
        loading: false,
        data: action.data.success ? state.data.filter(file => file.id !== action.data.fileId) : state.data,
        error: null
      };
    case ADD_FILE.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case DELETE_FILE.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
