export const baseUrl = ["/api", "v1"].join("/");

const ENDPOINTS = {
  NOMINATION_FORMS: {
    GET_ALL: [baseUrl, "nomination-forms"].join("/"),
    CREATE: [baseUrl, "nomination-forms"].join("/")
  },
  FILES: {
    UPLOAD: [baseUrl, "files"].join("/"),
    DELETE: fileId => [baseUrl, "files", fileId].join("/")
  }
};

export default ENDPOINTS;
