import axios from "axios";

import {FETCH_NOMINATION_FORMS, CREATE_NOMINATION_FORM} from "./action-types";
import ENDPOINTS from "../constants/api";

export const fetchAllNominationForms = () => dispatch => {
  dispatch({type: FETCH_NOMINATION_FORMS.START});

  return axios
    .get(ENDPOINTS.NOMINATION_FORMS.GET_ALL)
    .then(response => {
      dispatch({type: FETCH_NOMINATION_FORMS.SUCCESS, data: response.data});
    })
    .catch(error => {
      dispatch({type: FETCH_NOMINATION_FORMS.FAILED, error});
    });
};

export const createNominationForm = data => dispatch => {
  dispatch({type: CREATE_NOMINATION_FORM.START});

  return axios
    .post(ENDPOINTS.NOMINATION_FORMS.CREATE, data)
    .then(response => {
      dispatch({type: CREATE_NOMINATION_FORM.SUCCESS, data: response.data});
    })
    .catch(error => {
      dispatch({type: CREATE_NOMINATION_FORM.FAILED, error});
    });
};
