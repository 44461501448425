export const arrayToSubArrays = (array, lengths = []) => {
  if (!Array.isArray(array) || !lengths.length) return array;

  const temp = [...array],
    result = [];

  let lengthIdx = 0;
  while (temp.length) {
    const length = lengths[lengthIdx];
    result.push(temp.splice(0, length));

    lengthIdx++;
    if (lengthIdx >= lengths.length) {
      lengthIdx = 0;
    }
  }

  return result;
};
