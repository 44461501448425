/* eslint-disable import/no-anonymous-default-export */

import {FETCH_NOMINATION_FORMS, CREATE_NOMINATION_FORM} from "../actions/action-types";

const initialState = {data: [], loading: false, error: null};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOMINATION_FORMS.START:
    case CREATE_NOMINATION_FORM.START:
      return {...state, loading: true};
    case FETCH_NOMINATION_FORMS.SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null
      };
    case CREATE_NOMINATION_FORM.SUCCESS:
      return {
        loading: false,
        data: [...state.data, action.data],
        error: null
      };
    case FETCH_NOMINATION_FORMS.FAILED:
      return {
        loading: false,
        data: [],
        error: action.error
      };
    case CREATE_NOMINATION_FORM.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
