import axios from "axios";

import {ADD_FILE, DELETE_FILE} from "./action-types";
import ENDPOINTS from "../constants/api";

export const startUpload = () => dispatch => {
  dispatch({type: ADD_FILE.START});
};

export const addFile = file => dispatch => {
  dispatch({type: ADD_FILE.SUCCESS, data: file});
};

export const failUpload = error => dispatch => {
  dispatch({type: ADD_FILE.FAILED, error});
};

export const deleteFile = fileId => dispatch => {
  if (!fileId) {
    return;
  }

  dispatch({type: DELETE_FILE.START});

  return axios
    .delete(ENDPOINTS.FILES.DELETE(fileId))
    .then(response => {
      dispatch({type: DELETE_FILE.SUCCESS, data: {success: response.data, fileId}});
    })
    .catch(error => {
      dispatch({type: DELETE_FILE.FAILED, error});
    });
};
